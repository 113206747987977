<template>
  <div class="grid">
    <div class="col-12">

      <Panel>
        <template #header>
          <b>Yöneticiler</b>
        </template>
        <p>
          <DataTable
              :value="managerUsers"
              :paginator="true"
              sortMode="multiple"
              showGridlines
              :rows="10"
              :loading="loadingList"
              filterDisplay="menu"
              v-model:filters="filters"
              dataKey="id"
              :globalFilterFields="['name']"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          >
            <template #header>
        <span class="flex justify-content-between">
          <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Filtreleri Kaldır"
              class="p-button-outlined"
              @click="clearFilter()"
          />
          <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText
                      v-model="filters['global'].value"
                      placeholder="Aranacak Kelime"
                  />
                </span>
          </span>

            </template>
            <template #empty> Yönetici bulunamadı</template>
            <template #loading>
              Yöneticiler yükleniyor lütfen bekleyiniz.
            </template>
            <Column
                field="id"
                header="Id"
                :sortable="true"
                style="width: 130px"
            ></Column>
            <Column
                field="fullName"
                header="Operatör İsmi"
                :sortable="true"
                style="width: 500px"
            >
              <template #filter="{ filterModel }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Aranacak..."
                />
              </template>
            </Column>
            <Column
                field="isActive"
                header="Durum"
                dataType="boolean"
                style="min-width: 2rem"
                bodyClass="text-center"
            >
              <template #body="{ data }">
                <Button
                    disabled
                    icon="pi pi-check-circle"
                    class="p-button-rounded p-button-success mr-1"
                    v-if="data.isActive"
                />
                <Button
                    disabled
                    icon="pi pi-times-circle"
                    class="p-button-rounded p-button-danger mr-1"
                    v-if="!data.isActive"
                />
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
              </template>
            </Column>
            <Column :exportable="false" style="width: 130px">
              <template #body="slotProps">
                <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-success mr-2"
                    @click="edit(slotProps.data)"
                />
              </template>
            </Column>
          </DataTable>
        </p>
        <p>
          <Button label="Yeni Yönetici Ekle" icon="pi pi-plus" @click="addNew"></Button>
        </p>
      </Panel>
    </div>
  </div>

</template>

<script>

import {FilterMatchMode, FilterOperator} from "primevue/api";
import ManagerUserService from "../../services/managerUserService";

export default  {
  _managerUserService : null,
  data() {
    return {
      managerUsers:[],
      loadingList: true,
      filters: null,
    }
  },
  created() {
    this._managerUserService = new ManagerUserService();
    this.initFilters();
  },
  async mounted() {
    const response = await this._managerUserService.getAllManagerUsers();
    this.managerUsers = response.data;
    this.loadingList = false;
  },
  methods: {
    addNew() {
      this.$router.push({name: "managerUserAdd"});
    },
    edit(item) {
      this.$router.push({name: "managerUserEdit", params: {managerUserId: item.id}});
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        fullName: {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
        },
        isActive: {value: null, matchMode: FilterMatchMode.EQUALS}
      };
    },
  }
}

</script>