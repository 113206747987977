import appAxios from '@/utils/appAxios'
import {deleteDataById, getDataBydId, getPaginatedData, insertData, patchData} from "./commonService";

export default class ManagerUserService {

    async getUserByEmailAndPassword(email, password) {

        const response = await appAxios.get('/api/ManagerUser?email='+ email+ '&password=' + password);

        if(response.status === 200 && response.data.isSuccess)
        {
            return {
                isSuccess :true,
                data:response.data,
                message:""
            };
        }
        else{
            return {
                isSuccess: false,
                message: "E-Posta veya Şifreniz hatalı"
            }
        }
    }

    #defaultUrl = "/api/ManagerUser";
    #defaultResourceName = "Operator";

    async getAllManagerUsers() {
        return await getPaginatedData(this.#defaultUrl, this.#defaultResourceName);
    }

    async insertManagerUser(dataItem) {
        const createData = {
            "name": dataItem.name,
            "surName": dataItem.surName,
            "email":dataItem.email,
            "password": dataItem.password,
            "userType": "Operator",
            "isActive": "true"
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async getManagerUserById(operatorId) {
        return await getDataBydId(this.#defaultUrl, operatorId, this.#defaultResourceName);
    }

    async deleteById(operatorId) {
        return await deleteDataById(this.#defaultUrl, operatorId, this.#defaultResourceName);
    }

    async updateManager(operatorId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "name", "value": dataItem.name},
            {"op": "replace", "path": "surName", "value": dataItem.surName},
            {"op": "replace", "path": "email", "value": dataItem.email},
        ];
        if(dataItem.password != '')
        {
            patchDocument.push( {"op": "replace", "path": "password", "value": dataItem.password});
        }

        return await this.patchManager(operatorId, patchDocument);
    }
    async patchManager(id, patchDocument) {
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }
}